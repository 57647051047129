<template>
    <v-dialog :overlay-opacity="globalDialogAction.includes('logout') ? 1 : 0.5" persistent v-model="dialog" max-width="500">
        <v-card>
            <v-card-title>
                <div class="d-flex align-center secondary--text">
                    <v-icon class="mr-1">error</v-icon>
                    {{ globalDialogTitle }}
                </div>
            </v-card-title>
            <v-spacer></v-spacer>
            <v-divider></v-divider>
            <v-card-text class="mt-5">
                <span v-html="globalDialogText"></span>
                <div v-if="globalDialogTaskId || task">
                    <div class="my-2">Status: {{ taskStatusText }}</div>
                    <div v-if="isRunningTask" style="width: 100%; height: 100%" class="d-flex flex-column justify-center align-center">
                        <v-progress-circular v-if="isRunningTask" color="primary" indeterminate class="ml-2"></v-progress-circular>
                    </div>
                    <div v-else-if="isCompletedTask" style="width: 100%; height: 100%" class="d-flex flex-column justify-center align-center">
                        <v-icon large color="success">done</v-icon>
                    </div>
                    <div v-else-if="isErrorTask">
                        <div class="d-flex flex-column justify-center align-center w-100">
                            <v-icon large color="error">error</v-icon>
                        </div>
                        <div v-html="taskResult" class="my-2"></div>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    @click="goToWelcomePage"
                    v-if="globalDialogAction.includes('returnToWelcomePage') || globalDialogAction.includes('refreshInstanceSnapshots')"
                    color="primary"
                    :disabled="fetchingSnapshots"
                    text
                    class="caption font-weight-bold">
                    go to dashboard
                </v-btn>
                <v-btn v-if="globalDialogAction.includes('logout')" @click="logout" color="primary" text class="caption font-weight-bold">logout</v-btn>
                <v-btn @click="decline()" v-if="globalDialogAction.includes('dismiss')" color="primary" text class="caption font-weight-bold">Close</v-btn>
                <v-btn @click="decline()" v-if="globalDialogAction.includes('cancel')" text class="caption">Cancel</v-btn>
                <v-btn @click="decline()" v-if="globalDialogAction.includes('back')" text class="caption">Back</v-btn>
                <v-btn @click="close()" v-if="globalDialogAction.includes('close')" color="primary" text class="caption font-weight-bold">Close</v-btn>
                <v-btn v-if="globalDialogAction.includes('reload')" color="primary" text class="caption font-weight-bold" @click="reloadPage">Reload</v-btn>
                <v-btn
                    v-if="globalDialogAction.includes('refreshInstanceSnapshots')"
                    @click="refreshInstanceSnapshots"
                    :loading="fetchingSnapshots"
                    color="primary"
                    text
                    class="caption font-weight-bold">
                    refresh
                </v-btn>
                <v-btn v-if="globalDialogAction.includes('accept')" @click="accept()" color="primary" text class="caption font-weight-bold">Accept</v-btn>
                <v-btn v-if="globalDialogAction.includes('confirm')" @click="accept()" color="primary" text class="caption font-weight-bold">Confirm</v-btn>
                <v-btn
                    v-if="globalDialogAction.includes('stop-application')"
                    @click="stopApp()"
                    color="error"
                    text
                    class="caption font-weight-bold"
                    :loading="stoppingApp"
                    e2e-global-stop>
                    Stop
                </v-btn>
                <v-btn v-if="globalDialogAction.includes('restart-application')" @click="reloadPage" color="error" text class="caption font-weight-bold">
                    Restart
                </v-btn>
                <v-btn v-if="globalDialogAction.includes('delete')" @click="accept" color="error" text class="caption font-weight-bold">Delete</v-btn>
                <v-btn color="primary" v-if="globalDialogAction.includes('toInstanceSnapshots')" @click="toInstanceSnapshots">
                    Restore a snapshot from archive
                </v-btn>
                <v-btn color="primary" v-if="globalDialogAction.includes('toSnapshotFiles')" @click="toSnaphostFiles">Delete some files</v-btn>
                <v-btn
                    color="primary"
                    v-if="globalDialogAction.includes('contactSupport') && $appConfig.VUE_APP_INTERCOM_ID"
                    class="caption font-weight-bold"
                    @click="openIntercom">
                    Contact Support
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { invitationsAndRequests } from '@/mixins/invitationsAndRequests'
import { intercomMethods } from '@/mixins/intercom.js'
import eventBus from '@/components/EventBus'
import { enumsData } from '@/mixins/enums'
import { fetchTask } from '@/apis'

export default {
    name: 'GlobalDialog',
    mixins: [invitationsAndRequests, enumsData, intercomMethods],
    data() {
        return {
            dialog: false,
            stoppingApp: false,
            taskStatus: null,
            taskResult: null,
            task: false
        }
    },
    created() {
        eventBus.$on('global-dialog-task-started', evt => {
            this.task = true
            this.taskStatus = this.taskStatusTypes.CREATED.value
        })
        eventBus.$on('global-dialog-task-finished', evt => {
            if (evt instanceof Error) {
                this.taskStatus = this.taskStatusTypes.FAILED.value
                this.taskResult = evt.message
            } else {
                this.taskStatus = this.taskStatusTypes.COMPLETED.value
            }
        })
    },
    computed: {
        ...mapState(['globalDialog', 'globalDialogTitle', 'globalDialogText', 'globalDialogAction', 'globalDialogTaskId', 'userOrgs', 'options']),
        ...mapState('instanceStore', ['fetchingSnapshots']),
        ...mapState('snapshotStore', ['fileAreaType']),
        ...mapState(['userInfo']),
        ...mapGetters('snapshotStore', ['currentFileLocalPath']),
        taskStatusText() {
            if (this.isRunningTask) {
                return 'Running...'
            }
            if (this.isCompletedTask) {
                return 'Completed'
            }
            return 'An error has occured!'
        },
        isRunningTask() {
            return this.taskStatus > 0 && this.taskStatus < 4
        },
        isCompletedTask() {
            return this.taskStatus === 4
        },
        isErrorTask() {
            return !this.isRunningTask && !this.isCompletedTask
        }
    },
    methods: {
        goToWelcomePage: function () {
            this.$router.push({ name: 'welcome' })
            this.$store.dispatch('hideGlobalDialog')
        },
        reloadPage: function () {
            location.reload()
        },
        logout() {
            this.$auth.logout()
        },
        refreshInstanceSnapshots: function () {
            this.$store.dispatch('instanceStore/fetchInstanceSnapshots', this.$route.params.iid)
        },
        decline() {
            this.task = false
            this.$store.dispatch('hideGlobalDialog')
            eventBus.$emit('global-dialog-decline', true)
            if (this.globalDialogAction.includes('fetch-snapshots')) {
                this.$store.dispatch('instanceStore/fetchInstanceSnapshots', this.$route.params.iid)
            }
            if (this.globalDialogAction.includes('refreshfiles')) {
                this.$store.dispatch('snapshotStore/fetchCurrentFiles', {
                    id: this.$route.params.snid,
                    route: this.$route
                })
            }
            if (this.globalDialogAction.includes('refreshTables')) {
                this.$store.dispatch('snapshotStore/fetchCurrentTables', this.$route.params.snid)
            }
            if (this.globalDialogAction.includes('refreshColumns')) {
                this.$store.dispatch('snapshotStore/fetchCurrentColumns', this.options.columnId)
            }
        },
        close() {
            this.$store.dispatch('hideGlobalDialog')
        },
        toInstanceSnapshots() {
            this.$router.push({ name: 'instance-snapshots' })
            this.$store.dispatch('hideGlobalDialog')
        },
        toTasks() {
            this.$router.push({ name: 'user-tasks' })
            this.$store.dispatch('hideGlobalDialog')
        },
        toSnaphostFiles() {
            this.$router.push({
                name: 'snapshot-files',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$route.params.snid,
                    fileArea: this.fileAreaType,
                    localPath: this.currentFileLocalPath
                }
            })
            this.$store.dispatch('hideGlobalDialog')
        },
        accept() {
            eventBus.$emit('global-dialog-accept', true)
            this.$store.dispatch('hideGlobalDialog')
        },
        stopApp() {
            const aid = this.$route.query.stopAid
            const sessionId = this.$route.query.stopSessionId
            this.stoppingApp = true
            if (this.$route.params.snid) {
                this.$router.push({ name: 'snapshot-applications', params: this.$route.params })
            }
            this.$store.dispatch('appStore/stopApp', { aid, sessionId }).finally(() => {
                this.$store.dispatch('hideGlobalDialog')
                window.setTimeout(() => {
                    this.$store.dispatch('appStore/updateDeploymentStatus')
                }, 2000)
                this.stoppingApp = false
            })
        },
        openIntercom() {
            this.showNewMessageIntercom('', {
                app_id: this.$appConfig?.VUE_APP_INTERCOM_ID,
                ...this.userInfo
            })
            window.Intercom?.('show')
        }
    },
    watch: {
        async globalDialog(nextVal, preVal) {
            this.dialog = nextVal
            if (this.dialog && this.globalDialogTaskId) {
                this.taskStatus = 1
                this.taskResult = ''
                const taskResult = await fetchTask(this.globalDialogTaskId)
                if (taskResult instanceof Error) {
                    this.taskStatus = this.taskStatusTypes.FAILED.value
                } else {
                    this.taskStatus = taskResult.data.status
                    this.taskResult = taskResult.data.result
                }
            } else {
                this.taskResult = ''
            }
        },
        isCompletedTask() {
            if (this.globalDialogAction.includes('autoclose')) {
                window.setTimeout(() => {
                    this.decline()
                }, 1000)
            }
        }
    },
    beforeDestroy() {
        this.$store.dispatch('hideGlobalDialog')
        eventBus.$off('global-dialog-task-started')
        eventBus.$off('global-dialog-task-finished')
    }
}
</script>
